import React, { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { ColumnType } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import { Button, Popconfirm, Space, Table } from 'antd'
import { DeleteOutlined, EditOutlined, OrderedListOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { VariableType } from '../../../components/Table/types'
import { dimensionListSelector } from '../../../redux/context/dimensions/selectors'
import { InvestmentSummaryInvestment } from '../../../types/investment/Investment'
import { formatValueByType, propertyFilterProps, propertySorterProps } from '../../../utils/helpers'
import { useColumnSearchProps } from '../../../components/Table/useColumSearchProps'
import InvestmentModal from './InvestmentModal'
import { useDeleteInvestment } from '../hooks/useDeleteInvestment'
import { investmentsPageSelector } from '../../../redux/pages/investments/selectors'
import { useFiscalYearToDateLabel } from '../hooks/hooks'
import { useColumnRangePicker } from '../../../components/Table/useColumnRangePicker'
import DepreciationModal from './InvestmenDeprecationModal'
import CustomHeaderCell from '../../../components/Table/CustomHeaderCell'

interface InvestmentTableProps {
  investments: InvestmentSummaryInvestment[]
  isExisting: boolean
}

const InvestmentTable: React.FC<InvestmentTableProps> = ({ investments, isExisting }) => {
  const { t } = useTranslation()
  const { mutateAsync: deleteInvestment } = useDeleteInvestment(isExisting)
  const [investment, setInvestment] = useState<InvestmentSummaryInvestment>()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [depreciationModalVisible, setDepreciationModalVisible] = useState<boolean>(false)
  const { date } = useSelector(investmentsPageSelector)
  const [showTaxDepreciation, setShowTaxDepreciation] = useState<boolean>(false)
  const dimensions = useSelector(dimensionListSelector)
  const fiscalYearToDateLabel = useFiscalYearToDateLabel()

  const investmentData = useMemo(() => investments.slice().sort((a, b) => a.id - b.id), [investments])

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const taxDeprecationFields = () => {
    if (!showTaxDepreciation) {
      return []
    }
    const taxDepreciationColumns: ColumnType<InvestmentSummaryInvestment>[] = [
      {
        title: t('investmentsPage:taxDeprecationPlanDeprecationMethodId'),
        dataIndex: ['taxDeprecationPlan', 'deprecationMethodId'],
        key: 'taxDeprecationPlan-deprecationMethodId',
        width: 100,
        render: val => val && t(`loanRepaymentMethod:${val}`)
      },
      {
        title: t('investmentsPage:percentageOrYear'),
        dataIndex: ['taxDeprecationPlan', 'value'],
        key: 'taxDeprecationPlan-value',
        width: 80,
        render: (val, record) => {
          if (record?.taxDeprecationPlan?.deprecationMethodId === 2)
            return formatValueByType(val, VariableType.percentage)
          return formatValueByType(val, VariableType.absolute)
        }
      },
      {
        title: t('investmentsPage:taxDeprecationPlanPlAccountCode'),
        dataIndex: ['taxDeprecationPlan', 'plAccountCode'],
        key: 'taxDeprecationPlan-plAccountCode',
        width: 80
      },
      {
        title: t('investmentsPage:taxDeprecationPlanBsAccountCode'),
        dataIndex: ['taxDeprecationPlan', 'bsAccountCode'],
        key: 'taxDeprecationPlan-bsAccountCode',
        width: 80
      },
      {
        title: t('investmentsPage:depreciationSelectedDate', { date: dayjs(date).format('MM/YYYY') }),
        dataIndex: ['summary', 'taxDeprecation'],
        key: 'summary-taxDeprecation',
        width: 60,
        render: value => formatValueByType(value, VariableType.absolute)
      },
      {
        title: t('investmentsPage:depreciationSelectedDate', { date: fiscalYearToDateLabel }),
        dataIndex: ['summary', 'taxFiscalYearDeprecation'],
        key: 'summary-taxFiscalYearDeprecation',
        width: 60,
        render: value => formatValueByType(value, VariableType.absolute)
      },
      {
        title: t('investmentsPage:residualDate', { date: dayjs(date).format('MM/YYYY') }),
        dataIndex: ['summary', 'taxResidual'],
        key: 'summary-taxResidual',
        width: 80,
        render: value => formatValueByType(value, VariableType.absolute)
      }
    ]
    return taxDepreciationColumns
  }

  const columns: ColumnType<InvestmentSummaryInvestment>[] = [
    {
      title: t('investmentsPage:nameOfAsset'),
      dataIndex: 'name',
      key: 'name',
      align: 'right',
      fixed: 'left',
      width: 115,
      sorter: (a, b) => a?.name?.localeCompare(b?.name || '') || 0,
      ...useColumnSearchProps('name')
    },
    {
      title: t('investmentsPage:purchasePrice'),
      dataIndex: 'value',
      align: 'right',
      fixed: 'left',
      width: 110,
      sorter: { compare: (a, b) => (a?.value || 0) - (b?.value || 0) },
      ...useColumnSearchProps('value'),
      render: val => formatValueByType(val, VariableType.absolute)
    },
    {
      title: t('investmentsPage:acquisitioDate'),
      dataIndex: 'date',
      key: 'date',
      width: 110,
      align: 'right',
      sorter: {
        compare: (a, b) => dayjs(a.date).diff(b.date)
      },
      ...useColumnRangePicker(['date'])
    },
    {
      title: t('investmentsPage:accountCodeInAccounting'),
      dataIndex: 'accountCode',
      key: 'accountCode',
      width: 125,
      align: 'right',
      sorter: propertySorterProps(['accountCode']),
      ...propertyFilterProps(investments, ['accountCode'])
    },
    {
      title: t('investmentsPage:deprecationPlanStartDate'),
      dataIndex: ['deprecationPlan', 'startDate'],
      key: 'deprecationPlan-startDate',
      width: 115,
      align: 'right',
      sorter: propertySorterProps(['deprecationPlan', 'startDate']),
      ...useColumnRangePicker(['deprecationPlan', 'startDate'])
    },
    {
      title: t('investmentsPage:deprecationPlanDeprecationMethodId'),
      dataIndex: ['deprecationPlan', 'deprecationMethodId'],
      key: 'deprecationPlan-deprecationMethodId',
      width: 125,
      align: 'right',
      render: val => t(`loanRepaymentMethod:${val}`),
      sorter: propertySorterProps(['deprecationPlan', 'deprecationMethodId']),
      filters: _.uniqBy(
        investments.map(inv => ({
          text: t(`loanRepaymentMethod:${inv.deprecationPlan.deprecationMethodId}`),
          value: inv.deprecationPlan.deprecationMethodId
        })),
        'text'
      ),
      onFilter: (value, record) => record.deprecationPlan?.deprecationMethodId === value
    },
    {
      title: t('investmentsPage:percentageOrYear'),
      dataIndex: ['deprecationPlan', 'value'],
      key: 'deprecationPlan-value',
      width: 60,
      align: 'right',
      render: (val, record) => {
        if (record?.deprecationPlan?.deprecationMethodId === 2) return formatValueByType(val, VariableType.percentage)
        return formatValueByType(val, VariableType.absolute)
      },
      sorter: propertySorterProps(['deprecationPlan', 'value']),
      ...propertyFilterProps(investments, ['deprecationPlan', 'value'])
    },
    {
      title: t('investmentsPage:deprecationPlanPlAccountCode'),
      dataIndex: ['deprecationPlan', 'plAccountCode'],
      key: 'deprecationPlan-plAccountCode',
      width: 145,
      align: 'right',
      sorter: propertySorterProps(['deprecationPlan', 'plAccountCode']),
      ...propertyFilterProps(investments, ['deprecationPlan', 'plAccountCode'])
    },
    {
      title: t('investmentsPage:deprecationPlanBsAccountCode'),
      dataIndex: ['deprecationPlan', 'bsAccountCode'],
      key: 'deprecationPlan-bsAccountCode',
      width: 145,
      align: 'right',
      sorter: propertySorterProps(['deprecationPlan', 'bsAccountCode']),
      ...propertyFilterProps(investments, ['deprecationPlan', 'bsAccountCode'])
    },
    {
      title: t('investmentsPage:dimensionId'),
      dataIndex: ['deprecationPlan', 'dimensionId'],
      key: 'deprecationPlan-dimensionId',
      width: 110,
      align: 'right',
      render: val => {
        const di = dimensions?.find(d => d.dimensionId === val)
        return di?.name
      },
      sorter: propertySorterProps(['deprecationPlan', 'dimensionId']),
      filters: _.uniqBy(
        investments.map(inv => ({
          text: dimensions?.find(d => d.dimensionId === inv.deprecationPlan.dimensionId)?.name,
          value: inv.deprecationPlan.dimensionId
        })),
        'text'
      ),
      onFilter: (value, record) => record.deprecationPlan?.dimensionId === value
    },
    {
      title: t('investmentsPage:balanceSheetRow'),
      dataIndex: 'balanceSheetRowId',
      key: 'balanceSheetRowId',
      width: 105,
      align: 'right',
      render: val => t(`balanceSheet:${val}`)
    },
    {
      title: t('investmentsPage:depreciationSelectedDate', { date: dayjs(date).format('MM/YYYY') }),
      dataIndex: ['summary', 'deprecation'],
      key: 'summary-deprecation',
      width: 60,
      align: 'right',
      render: val => formatValueByType(val, VariableType.absolute)
    },
    {
      title: t('investmentsPage:depreciationSelectedDate', { date: fiscalYearToDateLabel }),
      dataIndex: ['summary', 'fiscalYearDeprecation'],
      key: 'summary-fiscalYearDeprecation',
      width: 60,
      align: 'right',
      render: val => formatValueByType(val, VariableType.absolute)
    },
    {
      title: t('investmentsPage:residualDate', { date: dayjs(date).format('MM/YYYY') }),
      dataIndex: ['summary', 'taxResidual'],
      key: 'summary-taxResidual',
      width: 80,
      align: 'right',
      render: value => formatValueByType(value, VariableType.absolute)
    },
    {
      title: t('investmentsPage:taxDeprecationPlanStartDate'),
      dataIndex: ['taxDeprecationPlan', 'startDate'],
      key: 'taxDeprecationPlan-startDate',
      width: 80,
      align: 'right',
      render: value => dayjs(value).format('DD.MM.YYYY')
    },
    ...taxDeprecationFields(),
    {
      title: t('investmentsPage:depreciations'),
      dataIndex: 'depreciations',
      key: 'depreciations',
      width: 65,
      align: 'left',
      fixed: 'right',
      render: (value, record) => (
        <Button
          block
          size="small"
          style={{ marginRight: '8px' }}
          icon={<OrderedListOutlined />}
          onClick={() => {
            setInvestment(record)
            setDepreciationModalVisible(true)
          }}
        />
      )
    },
    {
      title: t('global:actions'),
      key: 'actions',
      fixed: 'right',
      align: 'left',
      width: 80,
      render: record => (
        <Space>
          <Button
            size="small"
            type="link"
            icon={<EditOutlined />}
            onClick={() => {
              setInvestment(record)
              setModalVisible(true)
            }}
          />
          <Popconfirm
            placement="topRight"
            title={t('global:delete-confirm')}
            onConfirm={() => record && deleteInvestment(record)}
          >
            <Button danger size="small" type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      )
    }
  ]

  return (
    <>
      {investment && (
        <DepreciationModal
          investmentId={investment.id}
          visible={depreciationModalVisible}
          handleOk={() => setDepreciationModalVisible(false)}
        />
      )}
      {modalVisible && (
        <InvestmentModal
          onlyDepreciable
          visible={modalVisible}
          sourceDisabled={false}
          isExisting={isExisting}
          investment={investment}
          onFinish={toggleModal}
        />
      )}
      <Button style={{ float: 'right' }} onClick={() => setShowTaxDepreciation(!showTaxDepreciation)}>
        {showTaxDepreciation ? t('investmentsPage:hideTaxDepreciation') : t('investmentsPage:showTaxDepreciation')}
      </Button>
      <Table<InvestmentSummaryInvestment>
        scroll={{ x: 1900, y: 700 }}
        size="small"
        rowKey="id"
        bordered
        pagination={false}
        dataSource={investmentData}
        columns={columns}
        components={{
          header: {
            cell: CustomHeaderCell
          }
        }}
      />
    </>
  )
}

export default InvestmentTable
