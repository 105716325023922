import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { setToken } from '../../../redux/session/authentication/actions'
import { Credentials } from '../../../redux/session/authentication/types'
import { loginUser } from '../urls/users'
import { AppDispatch } from '../../../redux/store'

export function useLogin() {
  // TODO: Dispatch tyypitettävä jossain muualla jotenkin
  const dispatch = useDispatch() as AppDispatch

  return useMutation({
    mutationFn: (data: Credentials) => {
      return loginUser(data)
    },
    onSuccess: async token => {
      dispatch(setToken(token))
    },
    onError: (error, variables, context) => {
      console.error(error)
    }
  })
}
