import { Modal, Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React from 'react'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import { useQueryClient } from '@tanstack/react-query'
import { Loan } from '../types/Loan'
import { useCreateLoan } from '../queries/useCreateLoan'
import { LoanFormFields } from './LoanFormFields'
import { Purpose } from '../../../types/purpose/Purpose'
import { useResetFormOnCloseModal } from '../../../components/Form/hooks'
import loanQueryKeys from '../queries/loanQueryKeys'

interface InvestmentModalProps {
  visible: boolean
  onFinish: () => void
}

const LoanModal: React.FC<InvestmentModalProps> = ({ visible, onFinish }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [form] = useForm<Loan>()
  const createLoan = useCreateLoan()

  useResetFormOnCloseModal({ form, open: visible })

  const handleCancel = () => {
    onFinish()
    form.resetFields()
  }

  const handleOk = () => {
    form.submit()
  }

  const handleSubmit = async () => {
    try {
      const fieldsValue = await form.validateFields()
      const values = {
        ...fieldsValue,
        nextInstalmentDate: dayjs(fieldsValue.nextInstalmentDate).format('YYYY-MM-DD'),
        purposes:
          (fieldsValue.purposes?.map(p => {
            return { name: p }
          }) as unknown as Purpose[]) || [],
        balanceSheetRowId: fieldsValue.balanceSheetRowId,
        value: fieldsValue.value,
        interestRate: fieldsValue.interestRate
      }

      await createLoan.mutateAsync(values)
      queryClient.invalidateQueries({
        queryKey: loanQueryKeys.summaries()
      })
      onFinish?.()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      width={800}
      title={t('loans:loanModalTitle', { context: 'create' })}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={createLoan.isPending}
      maskClosable={false}
      destroyOnClose
      forceRender={false}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <LoanFormFields isExisting addNew />
      </Form>
    </Modal>
  )
}

export default LoanModal
