import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { ColumnProps } from 'antd/lib/table'
import { useBackend } from '../../../services/backend'
import { Dimension, DimensionQueryOperator } from '../../../types/dimension/Dimension'
import { currentUserSortedCompaniesSelector } from '../../../redux/session/currentUser/selectors'
import { Report, ReportDataType, ReportRow } from '../../../redux/context/reports/types'
import { getCompanysKeyFigureIds, mapToGlobalKeyFigureStatement } from '../../../utils/keyFigures'
import { Company } from '../../../types/company/Company'
import { Currency } from '../../../types/currency/Currency'
import { Formula } from '../../../types/formula/Formula'
import { MultiPurposeParamsFormFields } from './form/MultiPurposeParamsForm'
import { CompanyTableRow } from './components/MultiPurposeDashboardTable'
import { formatValueToNumberByType } from '../../../utils/helpers'
import { DashboardPageType } from './MultiPurposeDashboard'
import { filtersSelector } from '../../../redux/context/filters/selectors'
import { getDashboardStartAndEndDates } from './utils'
import {
  multiPurposeDashboardPageSelector,
  multiPurposeDashboardSelector,
  multiPurposeDashboardsSelector
} from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import {
  addMultiPurposeDashboardDimensionKeyfigureStatements,
  addMultiPurposeDashboardDimensions,
  deleteMultiPurposeDashboard,
  resetMultiPurposeDashboardReports,
  setMultiPurposeDashboardActiveDashboard,
  setMultiPurposeDashboardAllKeyfigureStatements,
  setMultiPurposeDashboardFormulas,
  updateMultiPurposeDashboardDashboard
} from '../../../redux/pages/dashboard/multiPurposeDashboard/actions'
import { contextCompanySelector } from '../../../redux/context/company/selectors'
import { MultiPurposeDashboardParams } from '../../../redux/pages/dashboard/multiPurposeDashboard/types'
import { getDimensionTree } from '../../../components/Dimension/utils'
import { AppDispatch } from '../../../redux/store'

export type MultiPurposeDashboardContextType = {
  loading: boolean
  dashboard: MultiPurposeDashboardParams
  dimensionLoading: boolean
  loadingDimensionData?: boolean
  formSaveLoading?: boolean
  dashboardRef: React.MutableRefObject<MultiPurposeDashboardParams | undefined>
  expandedRowKeys: string[]
  multiPurposeDashboardPage?: DashboardPageType
  updateDashboard: (values: MultiPurposeParamsFormFields) => void
  fetchCompanyFormulas: (companyIds: string[]) => void
  csvData: (tableData: any[], columns: ColumnProps<CompanyTableRow>[], showEmptyRows: boolean) => string
  deleteDashboard: () => void
  handleTableExpand: (tableRow: CompanyTableRow) => void
  setExpandedRowKeys: (values: string[]) => void
}

export const MultiPurposeDashboardContext = React.createContext<MultiPurposeDashboardContextType | null>(null)

interface MultiPurposeDashboardProviderProps {
  multiPurposeDashboardPage: DashboardPageType
  children?: ReactNode
}

export enum DashboardRequest {
  companies = 'users/current/dashboards/multi-company',
  default = 'companies/{companyId}/dashboards/dimensions',
  compact = 'companies/{companyId}/dashboards/dimensions',
  scenarios = 'companies/{companyId}/dashboards/budgeting-scenarios'
}

const MultiPurposeDashboardProvider: React.FC<MultiPurposeDashboardProviderProps> = ({
  children,
  multiPurposeDashboardPage
}) => {
  const dispatch: AppDispatch = useDispatch()

  const {
    keyfigureStatements,
    dimensionKeyfigureStatements,
    formulas: formulaReports,
    dimensions: dimensionsMap
  } = useSelector(multiPurposeDashboardPageSelector)
  const dashboards = useSelector(multiPurposeDashboardsSelector)
  const dashboard = useSelector(multiPurposeDashboardSelector)!
  const contextCompany = useSelector(contextCompanySelector)
  const dashboardRef = useRef<MultiPurposeDashboardParams>()
  const myCompanies = useSelector(currentUserSortedCompaniesSelector)

  const [loadingDimensionData, setLoadingDimensionData] = useState(false)
  const [formSaveLoading, setFormSaveLoading] = useState(false)
  const { periodGroups } = useSelector(filtersSelector)

  const dimensionRequest = useBackend(`/api/companies/{companyId}/accounting/dimensions`)
  const formulaRequest = useBackend('/api/companies/{companyId}/settings/key-figures/formulas')
  const keyFigureRequest = useBackend('/api/companies/{companyId}/reporting/custom')
  const updateDashbordRequest = useBackend(`api/${DashboardRequest[multiPurposeDashboardPage]}/{dashboardId}`)
  const dimensionTreeRequest = useBackend('/api/companies/{companyId}/accounting/dimensions')
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
  const [companyReportsLoading, setCompanyReportsLoading] = useState<boolean>(false)
  const [dimensionReportsLoading, setDimensionReportsLoading] = useState<boolean>(false)

  const getTableTree = () => {
    if (!dashboard || !contextCompany) return []
    const notFetchedDimensions: { [key: string]: Dimension[] } = {}

    // Comapnies map
    let mapCompanies = dashboard?.companies || []
    if (contextCompany && mapCompanies?.length < 1) {
      mapCompanies = [contextCompany.id]
    }
    mapCompanies
      ?.filter((id: string) => !!myCompanies.find(c => c.id === id))
      .map((id: string) => {
        const company = myCompanies.find(c => c.id === id)!
        const tableRow: any = {
          ...company,
          children: company ? dimensionsMap[company.id] : undefined
        }
        // Dimension map
        const map = ({ children: c, ...o }: any): any => {
          const fetchedKeyFigureStatementIds = dimensionKeyfigureStatements[o.id]?.map(row => row.id) || []
          const dashboardKeyFigureIds = [...(dashboard?.keyFigures || []), ...(dashboard.formulas || [])]
          const notFetchedKeyFigureIds = dashboardKeyFigureIds.filter(
            keyFigureIdsid => !fetchedKeyFigureStatementIds.includes(keyFigureIdsid)
          )
          // If there's  notFetchedKeyFigureIds or no report get report
          if (notFetchedKeyFigureIds?.length || !Object.keys(dimensionKeyfigureStatements).includes(o.id.toString())) {
            if (!notFetchedDimensions[company.id]) {
              notFetchedDimensions[company.id] = []
            }
            notFetchedDimensions[company.id].push(o)
          }

          return {
            company,
            ...o,
            children: expandedRowKeys.includes(`dimension-${o.id}`) ? c?.map(map) : undefined // recursive call
          }
        }

        return {
          ...tableRow,
          children: expandedRowKeys.includes(`company-${company.id}`) ? tableRow.children?.map(map) : undefined
        }
      })

    return notFetchedDimensions
  }

  const transformParams = (values: MultiPurposeParamsFormFields): MultiPurposeDashboardParams => {
    const { period, ...rest } = values
    const [start, end] = period || []
    return {
      startDate: start?.startOf('month').format('YYYY-MM-DD'),
      endDate: end?.endOf('month').format('YYYY-MM-DD'),
      ...rest
    }
  }

  const fetchCompanyReports = (
    company: Company,
    data: MultiPurposeDashboardParams,
    dimensionIds?: string[]
  ): Promise<Report> => {
    const keyFigureIds = getCompanysKeyFigureIds(company, data?.keyFigures)
    const { startDate, endDate } = getDashboardStartAndEndDates(data, periodGroups, multiPurposeDashboardPage)

    if (
      multiPurposeDashboardPage === DashboardPageType.companies ||
      multiPurposeDashboardPage === DashboardPageType.compact
    ) {
      return keyFigureRequest.get({
        urlParams: { companyId: company.id },
        body: {
          params: {
            dimensions: JSON.stringify(
              dimensionIds?.map(id => ({
                k: id,
                o: DimensionQueryOperator.has,
                v: id
              }))
            ),
            keyFigureIds,
            formulaIds: data.formulas,
            periodGroups: [
              {
                id: dayjs(startDate).year(),
                startDate: startDate || '',
                endDate: endDate || ''
              },
              {
                id: -dayjs(startDate).year(),
                startDate: dayjs(startDate).subtract(12, 'M') || '',
                endDate: dayjs(endDate).subtract(12, 'M') || ''
              }
            ],
            currency: data.currency || Currency.EUR,
            dataTypes: [ReportDataType.actuals],
            ...(multiPurposeDashboardPage === DashboardPageType.companies && {
              budgetingScenarioId: undefined
            })
          }
        }
      })
    }
    return Promise.resolve([])
  }

  const fetchCompanyFormulas = async (companyIds: string[]) => {
    let filteredCompanies = companyIds.filter(companyId => !(companyId in formulaReports))
    if (filteredCompanies.length < 1 && contextCompany) {
      filteredCompanies = [contextCompany.id]
    }

    const promises = filteredCompanies.map(companyId => {
      return formulaRequest
        .get({
          urlParams: { companyId },
          body: {
            params: {
              ...(multiPurposeDashboardPage === DashboardPageType.companies && {
                budgetingScenarioId: undefined
              })
            }
          }
        })
        .then((res: Formula[]) => res)
        .catch(() => [])
    })
    if (promises) {
      const allData = await Promise.all(promises)
      const theData = filteredCompanies?.reduce((acc, companyId, i) => {
        return { ...acc, [companyId]: allData[i] }
      }, {} as { [key: string]: Formula[] })
      theData && dispatch(setMultiPurposeDashboardFormulas(theData))
    }
  }

  const fetchDimensions = (companyId: string): Promise<Dimension[]> => {
    return dimensionTreeRequest
      .get({
        urlParams: { companyId },
        body: {
          params: {
            ...(multiPurposeDashboardPage === DashboardPageType.companies && {
              budgetingScenarioId: undefined
            })
          }
        }
      })
      .then((res: Dimension[]) => getDimensionTree(res))
      .catch(() => [])
  }

  const updateCompanyReports = async (companies: string[], data: MultiPurposeDashboardParams) => {
    setCompanyReportsLoading(true)
    let mapCompanies = companies || []
    if (contextCompany && mapCompanies?.length < 1) {
      mapCompanies = [contextCompany.id]
    }
    async function fetchCompanyReportsChunk() {
      const promises = []
      for (let i = 0; i < mapCompanies.length; i += 150) {
        const companyIds = mapCompanies.slice(i, i + 150)
        const companyPromises = companyIds.map((companyId: string) => {
          const company = myCompanies.find(c => c.id === companyId)
          if (company) {
            return fetchCompanyReports(company, data)
              .then((res: any) => res)
              .catch(() => []) as Promise<any>
          }
          return Promise.resolve([])
        })

        // eslint-disable-next-line no-await-in-loop
        const results = await Promise.all(companyPromises)
        promises.push(...results)
      }
      return promises
    }
    const companyPromises = await fetchCompanyReportsChunk()
    const promises = companyPromises.reduce((arr: any, dimArr: any) => {
      arr.push(...dimArr)
      return arr
    }, [])

    if (promises) {
      const allData = await Promise.all(promises)
      const theData = mapCompanies?.reduce((acc, companyId) => {
        return {
          ...acc,
          [companyId]: mapToGlobalKeyFigureStatement(allData?.find(a => a.companyId === companyId)?.data || [])
        }
      }, {} as { [key: string]: ReportRow[] })
      theData && dispatch(setMultiPurposeDashboardAllKeyfigureStatements(theData))
    }
    setCompanyReportsLoading(false)
  }

  const fetchDimensionReports = async (dimensions: Dimension[], company: Company) => {
    setDimensionReportsLoading(true)
    const chunkArray = (arr: Dimension[], chunkSize: number) => {
      const chunkedArray = []
      for (let i = 0; i < arr.length; i += chunkSize) {
        chunkedArray.push(arr.slice(i, i + chunkSize))
      }
      return chunkedArray
    }

    const fetchAllReports = async (
      fetchCompany: Company,
      selectedDashboard: MultiPurposeDashboardParams,
      chunk: Dimension[]
    ) => {
      const promises = await fetchCompanyReports(
        fetchCompany,
        selectedDashboard,
        chunk.map(c => c.dimensionId)
      )
      // eslint-disable-next-line no-return-await
      return await Promise.all(promises)
    }

    const fetchAllDimensions = async (
      fetchCompany: Company,
      selectedDashboard: MultiPurposeDashboardParams,
      dimensionsArr: Dimension[]
    ) => {
      const chunkedDimensions = chunkArray(dimensionsArr, 150)
      const results = []
      for (const chunk of chunkedDimensions) {
        // eslint-disable-next-line no-await-in-loop
        const reports = await fetchAllReports(fetchCompany, selectedDashboard, chunk)
        results.push(...reports.map(r => r.data))
      }
      return results as unknown as Report[]
    }

    const reports = await fetchAllDimensions(company, dashboard, dimensions)

    dispatch(
      addMultiPurposeDashboardDimensionKeyfigureStatements(
        dimensions?.reduce((acc, { id }, i) => {
          return { ...acc, [id]: mapToGlobalKeyFigureStatement(reports[i]) }
        }, {} as { [key: string]: ReportRow[] })
      )
    )
    setDimensionReportsLoading(false)
  }

  const handleTableExpand = async (tableRow: CompanyTableRow) => {
    if (!tableRow.dimensionId && !dimensionsMap[tableRow.id]) {
      setLoadingDimensionData(true)
      const companyDimensions = await fetchDimensions(tableRow.id)
      dispatch(addMultiPurposeDashboardDimensions(tableRow.id, companyDimensions))
      setLoadingDimensionData(false)
    }
  }

  const updateReports = (newParams: MultiPurposeDashboardParams) => {
    const updateAll =
      dashboardRef.current?.currency !== newParams.currency ||
      dashboardRef.current?.startDate !== newParams.startDate ||
      dashboardRef.current?.endDate !== newParams.endDate

    if (updateAll) dispatch(resetMultiPurposeDashboardReports())

    const filteredCompanies = newParams?.companies?.filter((companyId: string) => {
      if (updateAll) return true
      const company = myCompanies.find(c => c.id === companyId)
      const fetchedKeyFigureStatementIds = keyfigureStatements[companyId]?.map(row => row.id) || []
      const dashboardKeyFigureIds = [...(newParams?.keyFigures || []), ...(newParams.formulas || [])]
      const notFetchedKeyFigureIds = dashboardKeyFigureIds.filter(id => !fetchedKeyFigureStatementIds.includes(id))

      if (company && notFetchedKeyFigureIds.length) {
        return true
      }
      return false
    })

    updateCompanyReports(filteredCompanies || [], newParams)
  }

  const updateDashboard = (values: MultiPurposeParamsFormFields) => {
    if (values.id) {
      setFormSaveLoading(true)
      updateDashbordRequest
        .put({
          urlParams: {
            companyId: multiPurposeDashboardPage !== DashboardPageType.companies ? contextCompany?.id : null,
            dashboardId: values.id
          },
          body: { data: transformParams(values) }
        })
        .then(() => {
          const transformedData = transformParams(values)
          dispatch(updateMultiPurposeDashboardDashboard(transformedData))
          updateReports(transformedData)
          dashboardRef.current = transformedData

          setFormSaveLoading(false)
        })
    }
  }

  const deleteDashboard = () => {
    updateDashbordRequest
      .delete({
        urlParams: {
          companyId: multiPurposeDashboardPage !== DashboardPageType.companies ? contextCompany?.id : null,
          dashboardId: dashboard?.id
        }
      })
      .then(() => {
        const deleteIndex = dashboards.findIndex(d => d.id === dashboard?.id)
        const prevDashboardId = dashboards[deleteIndex - 1]?.id
        const nextDashboardId = dashboards[deleteIndex + 1]?.id
        dispatch(setMultiPurposeDashboardActiveDashboard(prevDashboardId || nextDashboardId))
        dashboard?.id && dispatch(deleteMultiPurposeDashboard(dashboard?.id))
      })
  }
  const getColumnData = (row: any, column: ColumnProps<CompanyTableRow>) => {
    const { dataIndex } = column
    const columnData = row[dataIndex as string]
    if (typeof columnData === 'object') {
      const { currentValue } = columnData
      return formatValueToNumberByType(currentValue.value, currentValue.type)
    }
    return columnData
  }

  const getRowData = (row: any, columns: any[]) => {
    return columns.map(column => {
      return getColumnData(row, column)
    })
  }

  const csvData = (tableData: any[], columns: ColumnProps<CompanyTableRow>[], showEmptyRows: boolean): string => {
    if (!tableData) return ''
    const finalReport: any[] = []
    function traverse(rows: any[]) {
      for (const row of rows) {
        const rowData = getRowData(row, columns)
        // Slice 2 first columns off
        const hasData = rowData.slice(2).some(val => !!val)
        if (!showEmptyRows && hasData) {
          finalReport.push(rowData)
        }
        if (showEmptyRows) {
          finalReport.push(rowData)
        }
        if (row.children) traverse(row.children)
      }
    }

    traverse(tableData)
    const stringHeader = [columns.map(c => c.title).join(';')]
    const stringRows = finalReport.map(row => {
      return row.join(';') as string
    })
    const csv = stringHeader.concat(stringRows).join('\n')

    return csv
  }

  useEffect(() => {
    if (expandedRowKeys.length > 0) {
      const notFetchedDimensions = getTableTree()
      Object.entries(notFetchedDimensions).forEach(([companyId, dimensions]) => {
        const company = myCompanies.find(c => c.id === companyId)
        company && fetchDimensionReports(dimensions, company)
      })
    }
  }, [expandedRowKeys, dimensionsMap, dashboardRef.current])

  useEffect(() => {
    if (dashboard) {
      if (
        multiPurposeDashboardPage === DashboardPageType.companies ||
        multiPurposeDashboardPage === DashboardPageType.compact
      ) {
        if ((!dashboardRef.current && dashboard) || dashboardRef.current?.id !== dashboard?.id) {
          dispatch(resetMultiPurposeDashboardReports())
          setExpandedRowKeys([])
          dashboardRef.current = dashboard
          updateCompanyReports(dashboard?.companies || [], dashboard)
          fetchCompanyFormulas(dashboard?.companies || [])
        }
      }
    }
    if (
      dashboard &&
      multiPurposeDashboardPage !== DashboardPageType.companies &&
      multiPurposeDashboardPage !== DashboardPageType.compact
    ) {
      if ((!dashboardRef.current && dashboard) || dashboardRef.current?.id !== dashboard?.id) {
        if (contextCompany && !formulaReports[contextCompany.id]) {
          fetchCompanyFormulas(dashboard?.companies || [])
        }
      }
    }
  }, [dashboard?.id, periodGroups])

  return (
    <MultiPurposeDashboardContext.Provider
      value={{
        loading: dimensionReportsLoading || companyReportsLoading || dimensionTreeRequest.loading,
        dashboard,
        dashboardRef,
        loadingDimensionData,
        formSaveLoading,
        dimensionLoading: dimensionRequest.loading,
        expandedRowKeys,
        multiPurposeDashboardPage,
        updateDashboard,
        fetchCompanyFormulas,
        deleteDashboard,
        csvData,
        handleTableExpand,
        setExpandedRowKeys
      }}
    >
      {children}
    </MultiPurposeDashboardContext.Provider>
  )
}

export default MultiPurposeDashboardProvider
