import { QueryFunctionContext } from '@tanstack/react-query'
import { DELETE, GET, POST, PUT } from '../../../redux/middleware/types'
import loanQueryKeys from '../queries/loanQueryKeys'
import { LoanSummaryReport } from '../types/LoanSummary'
import loanEndpoints from '../api/loanEndpoints'
import request from '../../../services/api/request'
import { LoanInstalment } from '../types/LoanInstalment'
import { LoanInstalmentCorrection } from '../types/LoanInstallmentCorrection'
import { Loan } from '../types/Loan'

export const fetchLoanSummary = async ({
  queryKey: [{ companyId, date, isExisting, budgetingScenarioId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof loanQueryKeys)['summary']>>) => {
  return request<LoanSummaryReport>({
    method: GET,
    url: loanEndpoints.summary(companyId),
    params: { date, isExisting, budgetingScenarioId },
    signal
  })
}

export const fetchLoanExport = async ({
  companyId,
  date,
  isExisting,
  budgetingScenarioId
}: QueryFunctionContext<ReturnType<(typeof loanQueryKeys)['summary']>>['queryKey'][0]) => {
  return request<Blob>({
    method: GET,
    url: loanEndpoints.export(companyId),
    params: { date, isExisting, budgetingScenarioId },
    responseType: 'blob'
  })
}

export const fetchLoanTemplate = async () => {
  return request<Blob>({
    method: GET,
    url: loanEndpoints.template(),
    responseType: 'blob'
  })
}

export const createLoanImport = async ({
  data,
  companyId,
  budgetingScenarioId
}: {
  data: FormData
  companyId: string
  budgetingScenarioId?: number
}) => {
  return request<FormData>({
    headers: { 'Content-Type': 'multipart/form-data' },
    method: POST,
    url: loanEndpoints.import(companyId),
    params: { budgetingScenarioId },
    data
  })
}

export const fetchLoan = async (companyId: string, loanId: number) => {
  return request<Loan>({
    method: GET,
    url: loanEndpoints.detail(companyId, loanId)
  })
}

export const updateLoan = async (data: Loan, companyId: string, loanId: number, budgetingScenarioId?: number) => {
  return request<Loan>({
    method: PUT,
    url: loanEndpoints.detail(companyId, loanId),
    params: { budgetingScenarioId },
    data
  })
}

export const deleteLoan = async (companyId: string, loanId: number, budgetingScenarioId?: number) => {
  return request<Loan>({
    method: DELETE,
    url: loanEndpoints.detail(companyId, loanId),
    params: { budgetingScenarioId }
  })
}

export const createLoan = async (data: Omit<Loan, 'id'>, companyId: string, budgetingScenarioId?: number) => {
  return request<Loan>({
    method: POST,
    url: loanEndpoints.list(companyId),
    params: { budgetingScenarioId },
    data
  })
}

export const fetchLoanInstalments = async ({
  queryKey: [{ companyId, loanId, budgetingScenarioId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof loanQueryKeys)['instalments']>>) => {
  return request<LoanInstalment[]>({
    method: GET,
    url: loanEndpoints.instalments(companyId, loanId),
    params: { budgetingScenarioId },
    signal
  })
}

export const createLoanInstalmentsCorrection = async (
  data: Partial<LoanInstalmentCorrection>,
  companyId: string,
  loanId: number,
  budgetingScenarioId?: number
) => {
  return request<LoanInstalmentCorrection>({
    method: POST,
    url: loanEndpoints.corrections(companyId, loanId),
    params: { budgetingScenarioId },
    data
  })
}

export const updateLoanInstalmentsCorrection = async (
  data: Partial<LoanInstalmentCorrection>,
  companyId: string,
  loanId: number,
  correctionId: number,
  budgetingScenarioId?: number
) => {
  return request<LoanInstalmentCorrection>({
    method: PUT,
    url: loanEndpoints.correction(companyId, loanId, correctionId),
    params: { budgetingScenarioId },
    data
  })
}

export const deleteLoanInstalmentsCorrection = async (
  companyId: string,
  loanId: number,
  correctionId: number,
  budgetingScenarioId?: number
) => {
  return request<LoanInstalmentCorrection>({
    method: DELETE,
    url: loanEndpoints.correction(companyId, loanId, correctionId),
    params: { budgetingScenarioId }
  })
}
