import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { Investment } from '../../../types/investment/Investment'
import { deleteInvestment } from '../service/investmentService'
import { useInvestmentOptions } from './useInvestment'

export const useDeleteInvestment = (isExisting: boolean) => {
  const companyId = useSelector(contextCompanyIdSelector)!
  const queryClient = useQueryClient()
  const options = useInvestmentOptions(isExisting)

  return useMutation({
    mutationFn: (investment: Investment) => deleteInvestment(companyId, investment),
    mutationKey: ['investment'],
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: options.queryKey })
    }
  })
}
