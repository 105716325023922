import { Col, DatePicker, Form, Input, Row, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DimensionSelect from '../../../../components/Dimension/DimensionSelect'
import { FormattedInputNumber } from '../../../../components/Misc/FormattedInputNumber'
import AccountSelect from '../../../account/components/AccountSelect'
import { useAccounts } from '../../../account/queries/useFetchAccounts'

interface InvestmentDeprecationFieldsProps {
  fieldParent: 'deprecationPlan' | 'taxDeprecationPlan'
  plSelectAccountIds: number[]
  bsSelectBalanceSheetRowIds: number[]
  showDimension?: boolean
}

const InvestmentDeprecationFields: React.FC<InvestmentDeprecationFieldsProps> = ({
  plSelectAccountIds,
  bsSelectBalanceSheetRowIds,
  fieldParent,
  showDimension
}) => {
  const { t } = useTranslation()
  const { data: accounts } = useAccounts()

  const colProps = {
    xs: 24,
    sm: 12,
    md: 8,
    lg: 6,
    xl: 4
  }

  return (
    <Row gutter={24} align="bottom">
      <Form.Item name={[fieldParent, 'id']} hidden>
        <Input />
      </Form.Item>
      <Col {...colProps}>
        <Form.Item
          label={t('investmentsPage:startDate')}
          name={[fieldParent, 'startDate']}
          rules={[{ required: true, message: '' }]}
        >
          <DatePicker format="DD.MM.YYYY" style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col {...colProps}>
        <Form.Item
          label={t('financialStatementsPage:deprecation-method')}
          name={[fieldParent, 'deprecationMethodId']}
          rules={[{ required: true, message: '' }]}
        >
          <Select>
            <Select.Option value={1}>{t('financialStatementsPage:straight-line-depreciation')}</Select.Option>
            <Select.Option value={2}>{t('financialStatementsPage:reducing-balance-method')}</Select.Option>
          </Select>
        </Form.Item>
      </Col>

      <Col {...colProps}>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return prevValues?.[fieldParent]?.deprecationMethodId !== currentValues?.[fieldParent]?.deprecationMethodId
          }}
        >
          {({ getFieldValue }) =>
            getFieldValue([fieldParent, 'deprecationMethodId']) === 1 ? (
              <Form.Item
                name={[fieldParent, 'value']}
                label={t('investmentsPage:years')}
                rules={[{ required: true, message: '' }]}
              >
                <FormattedInputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            ) : (
              <Form.Item
                name={[fieldParent, 'value']}
                label={t('investmentsPage:percent')}
                rules={[{ required: true, message: '' }]}
              >
                <FormattedInputNumber percentage style={{ width: '100%' }} addonAfter="%" min={0} max={100} step={1} />
              </Form.Item>
              // eslint-disable-next-line prettier/prettier
            )
          }
        </Form.Item>
      </Col>
      <Col {...colProps}>
        <Form.Item
          label={t('investmentsPage:plAccountCode')}
          name={[fieldParent, 'plAccountCode']}
          rules={[{ required: true, message: '' }]}
        >
          <AccountSelect balanceSheetRowIds={plSelectAccountIds} />
        </Form.Item>
      </Col>
      <Col {...colProps}>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return prevValues?.accountCode !== currentValues?.accountCode
          }}
        >
          {({ getFieldValue }) => {
            const { statementRowId } = accounts?.find(a => a.code === getFieldValue('accountCode')) || {}
            return (
              <Form.Item
                label={t('investmentsPage:bsAccountCode')}
                name={[fieldParent, 'bsAccountCode']}
                rules={[{ required: true, message: '' }]}
              >
                <AccountSelect
                  selectableBalanceSheetRowId={fieldParent === 'deprecationPlan' ? statementRowId : undefined}
                  balanceSheetRowIds={bsSelectBalanceSheetRowIds}
                />
              </Form.Item>
            )
          }}
        </Form.Item>
      </Col>
      {showDimension && (
        <Col {...colProps}>
          <Form.Item label={t('global:dimension')} name={[fieldParent, 'dimensionId']}>
            <DimensionSelect />
          </Form.Item>
        </Col>
      )}
    </Row>
  )
}

export default InvestmentDeprecationFields

InvestmentDeprecationFields.defaultProps = {
  showDimension: true
}
