import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Space, Button, Popconfirm, App } from 'antd'
import { t } from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import { Loan } from '../types/Loan'
import { Investment } from '../../../types/investment/Investment'
import { fetchLoan } from '../service/loanService'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { useDeleteInvestment } from '../../investment/hooks/useDeleteInvestment'
import loanQueryKeys from '../queries/loanQueryKeys'

interface LoanTableActionsProps {
  loan: Loan
  isExisting: boolean
  toggleModal: (investment: Investment) => void
}

const LoanTableActions = ({ loan, isExisting, toggleModal }: LoanTableActionsProps) => {
  const { message } = App.useApp()
  const companyId = useSelector(contextCompanyIdSelector)!
  const deleteInvestment = useDeleteInvestment(isExisting)
  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: loanQueryKeys.summaries()
    })
  }

  return (
    <Space>
      <Button
        type="link"
        size="small"
        onClick={async () => {
          try {
            const data = await fetchLoan(companyId, loan.id)
            if (data.investment) {
              toggleModal(data.investment)
            }
          } catch (err) {
            message.error('Failed loading Investment')
          }
        }}
        icon={<EditOutlined />}
      />
      <Popconfirm
        placement="bottomRight"
        title={t('global:delete-confirm')}
        onConfirm={async () => {
          try {
            const data = await fetchLoan(companyId, loan.id)
            if (data.investment) {
              await deleteInvestment.mutateAsync(data.investment, {
                onSuccess
              })
            }
          } catch (err) {
            message.error('Failed deleting Loan')
          }
        }}
        okText={t('global:yes')}
        cancelText={t('global:no')}
      >
        <Button type="link" size="small" danger icon={<DeleteOutlined />} />
      </Popconfirm>
    </Space>
  )
}

export default LoanTableActions
