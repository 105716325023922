import { Popover, Table } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ColumnType } from 'antd/lib/table'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { calculatedReportSelector } from '../../../../../redux/context/customReports/selectors'
import { getCategoryString } from '../../../../../redux/context/customReports/utils'
import { getCellDataAttributes } from '../../../../../components/Table/utils'
import { CustomTree } from './CustomTree'
import { formatValueByType, parseRGBA } from '../../../../../utils/helpers'
import Restricted from './Restricted'
import { getColor, getDataSourceCategories, renderTitle, showArrow, useTableHeight } from './utils'
import { filtersSelector } from '../../../../../redux/context/filters/selectors'
import ResizableTitle from '../../../../../components/Table/ReziableTitle'
import { useResizeableColumns } from '../../../../../components/Table/hooks'
import { CustomReportCategory, CustomReportCategoryRow } from '../../../../../redux/context/customReports/typesCategory'
import { CustomReportTableSection } from '../../../../../redux/context/customReports/typesSection'
import { CustomReportVariable, CustomReportVariableRow } from '../../../../../redux/context/customReports/typesVariable'
import Arrow from '../../../../../components/Misc/Arrow'
import ReferenceRulesTable from '../../../../settings/company/keyFigures/formulas/components/ReferenceRules/ReferenceRulesTable'

const StyledTable: typeof Table = styled(Table)`
  & .bold {
    font-weight: bold;
  }

  & .ant-table-cell.ant-table-column-has-sorters.react-resizable {
    overflow: hidden;
  }
`

const defaultProps = {
  columnWidth: 110,
  nameColumnWidth: 130
}

interface CustomReportProps {
  section: CustomReportTableSection
}

const CustomReportTableTwo: React.FC<CustomReportProps> = ({
  section: { variables, categories, title: sectionTitle }
}) => {
  const { t, i18n } = useTranslation()
  const tableElement = useRef(null)
  const [tableColumns, setTableColumns] = useResizeableColumns<CustomReportCategoryRow>([])
  const { sections = [] } = useSelector(calculatedReportSelector) || {}
  const [categoryTree] = useState(new CustomTree('0'))
  const { displayEmptyRows } = useSelector(filtersSelector)

  useMemo(() => categoryTree.build(categories), [categories])
  const dataSource = useMemo(
    () => getDataSourceCategories(categoryTree, variables, displayEmptyRows),
    [variables, categories, displayEmptyRows]
  )
  const tableHeight = useTableHeight(tableElement, dataSource)
  const getColumnWidth = () => {
    if (variables.length <= 12) return undefined

    return defaultProps.columnWidth
  }

  const getErrors = (variable: CustomReportVariable, category: CustomReportCategory) => {
    return variable?.balances?.find(r => r.groupId === category.id)?.errors || null
  }

  const getTableColumn = (variable: CustomReportVariable): ColumnType<CustomReportCategoryRow> => {
    return {
      title: variable.style?.referenceValueRules ? (
        <Popover content={<ReferenceRulesTable rules={variable.style?.referenceValueRules} />}>
          {renderTitle(variable, variables)}
        </Popover>
      ) : (
        renderTitle(variable, variables)
      ),
      dataIndex: ['values', `${variable.id}`],
      width: getColumnWidth(),
      align: 'right',
      key: variable.id,
      sorter: (a, b) => {
        const aValue = a?.values?.[variable.id ?? ''] || 0
        const bValue = b?.values?.[variable.id ?? ''] || 0
        return aValue - bValue
      },

      onCell: record => {
        const val = record.values?.[`${variable?.id}`]
        const color = getColor(record?.leaf, variable as unknown as CustomReportVariableRow, val)
        return {
          style: {
            backgroundColor: color,
            fontWeight: variable.style?.fontWeight ? 'bold' : undefined
          },
          ...getCellDataAttributes(record, variable.type, val)
        } as React.HTMLAttributes<any>
      },
      render: (val: number, record) => {
        const variableType = record.variableType || variable.type
        const errors = getErrors(variable, record.leaf)
        if (errors) {
          return <Restricted errors={errors} />
        }

        return (
          <>
            {formatValueByType(val, variableType)}
            {showArrow(val, record.leaf, variable) && (
              <Arrow value={val} thresholdValueSetting={variable.style?.thresholdValueSetting} />
            )}
          </>
        )
      }
    }
  }

  useEffect(() => {
    const onCell = (val: CustomReportCategoryRow) => {
      let color = getColor(val?.leaf as unknown as CustomReportCategory)
      const rbga = parseRGBA(color)
      // Set alpha 1 on fixed rows
      if (rbga) {
        rbga.alpha = 1
        color = `rgb(${[...Object.values(rbga)].join(',')})`
      }
      return {
        style: {
          backgroundColor: color
        }
      }
    }

    const getDynamicColumnWidth = (type: 'company' | 'dimension'): number => {
      const colWidth = 120
      const titleLengths: number[] = []
      dataSource.forEach(d => {
        titleLengths.push(d?.[type]?.title?.length || 0)
      })
      const maxTitleLength = Math.max(...titleLengths)
      return maxTitleLength * 6 < colWidth ? colWidth : maxTitleLength * 6
    }

    const fixedColumns: ColumnType<CustomReportCategoryRow>[] = [
      {
        title: t('customReportCategoryType:company'),
        key: 'company',
        dataIndex: 'company',
        fixed: 'left' as const,
        ellipsis: true,
        width: getDynamicColumnWidth('company'),
        onCell,
        render: (val: CustomReportCategory) => getCategoryString(val, categories)
      },
      {
        title: t('customReportCategoryType:dimension'),
        key: 'dimension',
        dataIndex: 'dimension',
        fixed: 'left' as const,
        ellipsis: true,
        width: getDynamicColumnWidth('dimension'),
        onCell,
        render: (val: CustomReportCategory) => getCategoryString(val, categories)
      },
      {
        title: t('customReportCategoryType:dataType'),
        key: 'dataType',
        dataIndex: 'dataType',
        fixed: 'left' as const,
        ellipsis: true,
        width: 80,
        onCell,
        render: (value: string) => t(`global:${value}`)
      }
    ].filter(c => [...categoryTree.tableColumns].includes(c.key))

    fixedColumns.push({
      title: t('customReportCategoryType:leaf'),
      fixed: 'left' as const,
      align: 'right' as const,
      width: 120,
      ellipsis: true,
      onCell,
      render: (val: any) => {
        return {
          children: getCategoryString(val.function || val.periodGroup, categories),
          props: {
            'data-t': 's'
          } as any
        }
      }
    })
    setTableColumns([...fixedColumns, ...(variables || []).map(getTableColumn)])
    return () => setTableColumns([])
  }, [variables, i18n.language, sections])

  return (
    <div className="report-table-container" style={{ height: '100%' }} data-title={sectionTitle} ref={tableElement}>
      <StyledTable<CustomReportCategoryRow>
        className="table-darken-levels"
        data-name={sectionTitle}
        components={{
          header: {
            cell: ResizableTitle
          }
        }}
        columns={tableColumns}
        dataSource={dataSource}
        bordered
        size="small"
        rowKey={record => record.id}
        scroll={{
          x: variables.length * defaultProps.columnWidth + defaultProps.nameColumnWidth,
          y: tableHeight
        }}
        pagination={false}
      />
    </div>
  )
}

export default CustomReportTableTwo
